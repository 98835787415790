<template>
  <div class="h-screen flex justify-center items-center">
    <section class="md:w-2/5 m-auto bg-white">
      <div v-if="showErrorMessage">
        <p>{{ errorMessage }}</p>
      </div>
      <ProfilePersonalInfo />
    </section>
  </div>
</template>

<script setup>
import { ref } from "vue";
import ProfilePersonalInfo from "@/components/profile/ProfilePersonalInfo.vue";
const errorMessage = ref("Something went wrong, please try again!");
const showErrorMessage = ref(false);
</script>

<style>
.submit-btn {
  background-color: #edfe86;
  border: none;
}
</style>
