<template>
  <form @submit.prevent="submitChangeInformation" class="w-full">
    <h2 class="p-5 font-bold text-lg">Personal Information</h2>
    <div class="m-auto border-b"></div>
    <div class="p-6">
      <div class="w-full form__div">
        <input
          class="form__input"
          type="text"
          id="first_name"
          placeholder=" "
          v-model="firstName"
        />
        <label for="first_name" class="form__label">First Name</label>
      </div>
      <div class="w-full form__div">
        <input
          class="form__input"
          type="text"
          id="last_name"
          placeholder=" "
          v-model="lastName"
        />
        <label for="last_name" class="form__label">Last Name</label>
      </div>
      <div class="w-full form__div">
        <input
          class="form__input"
          type="email"
          id="email"
          placeholder=" "
          v-model="email"
        />
        <label for="email" class="form__label">Email</label>
      </div>
      <Alert :alert="alert" />
      <div class="flex justify-between items-center my-3">
        <div>
          <router-link 
            :to="{ name: 'Change Password' }" 
            class="text-color underline"
          >Change Password</router-link>
        </div>
        <div>
          <button class="text-color submit-btn font-bold px-4 py-1" type="submit">
            Change info
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script setup>
import { ref, computed } from "vue";
import { expressions } from "@/components/general/utils";
import Alert from "@/components/general/Alert";
import { useStore } from "vuex";
import { editUser } from "@/services/user/user";

const store = useStore();
const user = computed(() => store.getters["user/getUserData"]);

const firstName = ref(user.value.firstName);
const lastName = ref(user.value.lastName);
const email = ref(user.value.email);

const alert = ref({ message: "", active: false, error: false });

async function submitChangeInformation() {
  if (!expressions.email.test(email.value.trim())) {
    handleAlert("Invalid Email", true);
    return;
  }
  if (
    !expressions.letters.test(firstName.value.trim()) ||
    !expressions.letters.test(lastName.value.trim())
  ) {
    handleAlert("The first and last name fields are text only!", true);
    return;
  }

  await submintRequest();
  store.commit("user/setFullName", {
    firstName: firstName.value,
    lastName: lastName.value,
  });
  store.commit("user/setEmail", email.value);
  handleAlert("The profile has been updated!", false);
}

async function submintRequest() {
  try {
    await editUser(user.value.id, {
      ...(firstName.value !== user.value.firstName
        ? { first_name: firstName.value }
        : {}),
      ...(lastName.value !== user.value.lastName
        ? { last_name: lastName.value }
        : {}),
      register_user: {
        ...(email.value !== user.value.email ? { email: email.value } : {}),
      },
    });
  } catch (error) {
    handleAlert(error.message, true);
  }
}

function handleAlert(message, error) {
  alert.value = { message, active: true, error };
  setTimeout(() => {
    alert.value.active = false;
  }, 3000);
}
</script>
